/* Standard Eric Meyer reset */
/* http://meyerweb.com/eric/tools/css/reset/
   v4.0 | 20180602
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, main, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section {
	display: block;
}
/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
    display: none;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/* Get the variables in early so they're available */
/*
  The breakpoints for all resolutions represented as pairs with the breakpoint name and its associated pixel width.
  @media (--medium) { ...styles... }
*/
/* Above-targt breakpoints */
/* Below-target breakpoints */
/* Motion reduction breakpoint */
/* High contrast breakpoints */
/*
  Allows you to create your own selectors
  http://cssnext.io/features/#custom-selectors
  .MyModule:--enter {
    ...
  }
*/
/*
  The z-indexes for the project.
  z-index: var(--zindex--nav);
*/
/* Import the utility classes */
.sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}
.color--text {
    color: #2c2b2b;
  }
.color--action {
    color: #438aff;
  }
.color--primary-1 {
    color: #2c2b2b;
  }
.color--primary-2 {
    color: #ff5454;
  }
.color--primary-3 {
    color: #fff;
  }
.color--primary-4 {
    color: #f2f2f2;
  }
.color--secondary-1 {
    color: #ff343c;
  }
.color--secondary-2 {
    color: #438aff;
  }
.color--secondary-3 {
    color: #0bffca;
  }
.color--secondary-4 {
    color: #d867ff;
  }
.color--white {
    color: #fff;
  }
.color--white .heading {
      color: #ff5454;
    }
.color--white .link:hover,.color--white .link:active,.color--white .link:focus {
        color: #fff;
      }
.color--gray-1 {
    color: #f2f2f2;
  }
.color--black {
    color: #2c2b2b;
  }
.bg--primary-1 {
    background-color: #2c2b2b;
  }
.bg--primary-2 {
    background-color: #ff5454;
  }
.bg--primary-3 {
    background-color: #fff;
  }
.bg--primary-4 {
    background-color: #f2f2f2;
  }
.bg--secondary-1 {
    background-color: #ff343c;
  }
.bg--secondary-2 {
    background-color: #438aff;
  }
.bg--secondary-3 {
    background-color: #0bffca;
  }
.bg--secondary-4 {
    background-color: #d867ff;
  }
.bg--white {
    background-color: #fff;
  }
.bg--gray-1 {
    background-color: #f2f2f2;
  }
.bg--black {
    background-color: #2c2b2b;
  }
.rhythm > * + *, .rhythm--default > * + * { margin-top: 1em; }
.rhythm--small > * + * { margin-top: 0.5em; }
.rhythm--large > * + * { margin-top: 2em; }
.rhythm--extra-large > * + * { margin-top: 4em; }
.rhythm--deep * + *, .rhythm--deep-default * + * { margin-top: 1em; }
.rhythm--deep-small * + * { margin-top: 0.5em; }
.rhythm--deep-large * + * { margin-top: 2em; }
.rhythm--deep-extra-large * + * { margin-top: 4em; }
.text--align-center {
    text-align: center;
  }
.text--align-left {
    text-align: left;
  }
.text--align-right {
    text-align: right;
  }
.text--bold {
    font-weight: 700;
  }
.text--italic {
    font-style: italic;
  }
.text--light {
    font-weight: 300;
  }
.text--small {
    font-size: .875rem;
  }
/* Import root styles */
* {
  box-sizing: border-box;
}
html, body {
  box-sizing: inherit;
  color: #2c2b2b;
  font-family: 'Work Sans', sans-serif;
  line-height: 26px;
  scroll-behavior: smooth;
}
html.lock-body, body.lock-body {
    overflow: hidden;
  }
strong {
  font-weight: 700;
}
/* Import all component styles */
.aspect {
  position: relative;
}
.aspect::before {
    display: block;
    content: '';
  }
.aspect > * {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
.aspect--16by9::before {
      padding-top: 56.25%;
      content: '';
    }
.aspect--4by3::before {
      padding-top: 75%;
      content: '';
    }
.aspect--2by1::before {
      padding-top: 50%;
      content: '';
    }
.asterisk {
  color: #438aff;
  font-weight: 700;
}
.blockquote, blockquote {
  padding: 1.5rem;
  font-family: 'Courier', monospace;
  font-size: 1.5rem;
  font-weight: 700;
}
.blockquote .figcaption, blockquote .figcaption {
    font-family: 'Work Sans', sans-serif;
    font-size: 1.125rem;
    font-weight: 300;
    margin-top: 1rem;
  }
.brand {
  display: inline-block;
}
.brand--bordered {
    border: 1px solid #d2d2d2;
  }
.button {
  background-color: #ff5454;
  border: 2px solid transparent;
  border-radius: 1.5rem;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: 'Courier', monospace;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1;
  min-width: 8.5rem;
  outline: none;
  padding: .85rem 2.25rem;
  text-decoration: none;
  transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
  white-space: nowrap;
}
.button:hover {
    background-color: #2c2b2b;
    text-decoration: none;
  }
.button:active {
    background-color: #438aff;
  }
.button:focus {
    background-color: #438aff;
    outline: #2c2b2b auto 5px;
  }
.button[disabled] {
    background-color: rgba(255, 84, 84, 0.5);
    cursor: default;
  }
.button--icon {
    align-items: center;
    display: inline-flex;
  }
.button--icon svg {
      flex: 0 0 auto;
      height: 1rem;
      margin: 0 0 0 1ch;
      width: 1rem;
    }
.button--icon svg path {
        fill: #fff;
        transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
      }
.button--outlined {
    background-color: transparent;
    border-color: #438aff;
    color: #438aff;
  }
.button--outlined:hover {
      background-color: #438aff;
      color: #fff;
    }
.button--outlined:active {
      background-color: #ff5454;
      border-color: #ff5454;
    }
.button--outlined:focus {
      color: #fff;
    }
.button--outlined[disabled] {
      background-color: inherit;
      border-color: rgba(255, 84, 84, 0.5);
      color: rgba(255, 84, 84, 0.5);
    }
.button--gradient {
    background: linear-gradient(220deg, #438aff 20%, #ff5454 100%);
  }
.button--gradient:hover {
      background: #438aff;
    }
.button--gradient:active {
      background: #ff5454;
    }
.button--gradient:focus {
      background: linear-gradient(220deg, #438aff 20%, #ff5454 100%);
    }
.button--gradient[disabled] {
      background: rgba(255, 84, 84, 0.5);
    }
.button--white {
    background: #fff;
    color: #2c2b2b;
  }
.button--white:hover,.button--white:active,.button--white:focus {
      background: #fff;
      color: #0bffca;
    }
.card {
  border: solid 1px #f2f2f2;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
}
.card--centered {
    align-items: center;
    border: none;
    display: flex;
    flex-direction: column;
  }
.card--shadowed {
    border: 0;
    box-shadow: 0 4px 8px 2px rgba(0, 0, 0, .125);
  }
.card--shadowed:hover,.card--shadowed:active,.card--shadowed:focus {
      box-shadow: 0 4px 8px 2px rgba(0, 0, 0, .25);
      transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
    }
.dl {
  display: flex;
  flex-wrap: wrap;
}
.dl .dt {
    flex: 1 0 33%;
    font-weight: 700;
  }
.dl .dt::after {
      content: ': ';
    }
.dl .dd {
    flex: 0 1 67%;
  }
.figure {
  /* Figure */
}
/* Standard Eric Meyer reset */
/* Get the variables in early so they're available */
/* Import the utility classes */
/* Import root styles */
/* Import all component styles */
.form__item {
    padding: 1.5rem;
  }
/* this selector activates when an element inside .inputtext receives focus */
.form__item:focus-within {
      background-color: #f2f2f2;
      transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
    }
.heading, .richtext h1, .richtext h2, .richtext h3, .richtext h4, .richtext h5, .richtext h6 {
  font-family: 'Courier', monospace;
  font-range: 320px 1280px; /* viewport widths between which font-size is fluid */
  line-height: 1.2em;
}
.heading--h1, .richtext h1 {
    font-size: calc(2rem + 0.25 * ((100vw - 26.25rem) / 53.75));
    font-weight: 700;
  }
.heading--h2, .richtext h2 {
    font-size: calc(1.25rem + 0.25 * ((100vw - 26.25rem) / 53.75));
    font-weight: 700;
  }
.heading--h3, .richtext h3 {
    font-size: calc(1.3125rem + 0 * ((100vw - 26.25rem) / 53.75));
  }
.heading--h4, .richtext h4 {
    font-size: calc(1.125rem + 0 * ((100vw - 26.25rem) / 53.75));
  }
.heading--h5, .richtext h5 {
    font-size: calc(.875rem + 0 * ((100vw - 26.25rem) / 53.75));
    font-weight: 700;
  }
.heading--h6, .richtext h6 {
    font-size: calc(.75rem + 0 * ((100vw - 26.25rem) / 53.75));
  }
.heading--bold {
    font-weight: 700;
  }
.heading--light {
    font-weight: 300;
  }
.heading--hero {
    color: #fff;
    font-family: 'Work Sans', sans-serif;
    font-size: calc(2rem + 3 * ((100vw - 26.25rem) / 53.75));
    font-weight: 900;
    text-transform: uppercase;
  }
.page-head--tall .heading--hero {
      font-size: calc(5rem + 7.5 * ((100vw - 26.25rem) / 53.75));
    }
.heading--enormous {
    display: block;
    font-family: 'Work Sans', sans-serif;
    font-size: calc(5rem + 7.5 * ((100vw - 26.25rem) / 53.75));
    font-weight: 900;
    text-transform: uppercase;
  }
.hr {
  border: none;
  height: 1px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.hr--default {
    background: #d2d2d2;
  }
.hr--dashed {
    border-top: 2px dashed #2c2b2b;
  }
.icon {
  display: inline-block;
  height: calc(5.625rem - 2rem);
  max-height: 100%;
  max-width: 100%;
  width: calc(5.625rem - 2rem);
}
.icon use {
    height: 100%;
    width: 100%;
  }
.icon--brand {
    height: calc(5.625rem + 4rem);
    width: calc(5.625rem + 4rem);
  }
.image, .richtext img {
  height:auto;
  max-width: 100%;
}
.image--rounded {
    border-radius: 100%;
  }
.image--background {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
.image__lazy-box {
      bottom: 4px;
      left: 0;
      opacity: 1;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 3;
    }
.image__lazy-box .image__low-res {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
        width: 100%;
      }
.image__lazy-panel {
      background-color: #f2f2f2;
      display: block;
      height: 100%;
      opacity: 0;
      position: absolute;
      width: 100%;
      z-index: 3;
    }
.image__lazy-wrapper {
      display: inline-block;
      position: relative;
    }
.image__lazy-wrapper.pre-load .image__low-res {
          filter: blur(6px);
        }
.image__lazy-wrapper.pre-load .image__lazy-panel {
          opacity: .5;
        }
.image__lazy-wrapper.is-loaded .image__low-res {
          filter: none;
        }
.image__lazy-wrapper.is-loaded .image__lazy-box {
          opacity: 0;
          transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
        }
.image__lazy-wrapper.is-loaded .image.is-lazy {
          opacity: 1;
          transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
        }
.input {
  background-color: #fff;
  border: 1px solid #f2f2f2;
  color: #2c2b2b;
  font-family: 'Work Sans';
  font-weight: 700;
  font-size: .875rem;
  line-height: 1;
  padding: .5rem;
}
.input:focus {
    outline-color: #2c2b2b;
  }
.input::placeholder {
    color: #d2d2d2;
    opacity: .8;
  }
.input[type="radio"] + .label::after {
        border-radius: calc(14px / 2);
        content: '';
      }
.input[type="radio"] + .label::before {
        border-radius: calc(24px / 2);
        content: '';
      }
.input[type="file"] {
    border-color: transparent;
  }
.input[type="date"], .input[type="datetime-local"], .input[type="time"], .input.select {
    background-position: top 50% right .5rem;
    background-repeat: no-repeat;
  }
/* removes the default calendar icon in webkit browsers */
.input[type="date"]::-webkit-calendar-picker-indicator, .input[type="datetime-local"]::-webkit-calendar-picker-indicator, .input[type="time"]::-webkit-calendar-picker-indicator, .input.select::-webkit-calendar-picker-indicator {
      background-image: none;
    }
.input[type="date"]:hover,.input[type="date"]:active,.input[type="date"]:focus, .input[type="datetime-local"]:hover, .input[type="datetime-local"]:active, .input[type="datetime-local"]:focus, .input[type="time"]:hover, .input[type="time"]:active, .input[type="time"]:focus {
      background-image: none !important;
    }
.input[type="checkbox"], .input[type="radio"] {
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: polygon(0, 0, 0, 0, 0, 0);
    -webkit-clip-path: polygon(0, 0, 0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
.input[type="checkbox"] + .label, .input[type="radio"] + .label {
      display: inline-block;
      font-weight: 500;
      height: 24px;
      margin-left: calc(2 * 1.5rem);
      position: relative;
    }
.input[type="checkbox"] + .label::after, .input[type="radio"] + .label::after {
        background-color: #2c2b2b;
        box-sizing: border-box;
        content: '';
        display: inline-block;
        height: 14px;
        left: calc(-2 * 24px + 5px);
        position: absolute;
        top: -1px;
        transform: scale(0);
        transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
        width: 14px;
      }
.input[type="checkbox"] + .label::before, .input[type="radio"] + .label::before {
        border: 2px solid #f2f2f2;
        box-sizing: border-box;
        content: '';
        display: inline-block;
        position: absolute;
        height: 24px;
        left: calc(-2 * 24px);
        top: -6px;
        transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
        width: 24px;
      }
.input[type="checkbox"]:focus + .label::before, .input[type="checkbox"]:active + .label::before, .input[type="radio"]:focus + .label::before, .input[type="radio"]:active + .label::before {
        border-color: #2c2b2b;
      }
.input[type="checkbox"]:checked + .label::after, .input[type="radio"]:checked + .label::after {
          background-color: #2c2b2b;
          transform: scale(1);
        }
.label {
  color: #646464;
  cursor: pointer;
  display: block;
  font-size: .75rem;
  font-weight: 700;
  text-transform: uppercase;
}
.link, .richtext a {
  color: #ff5454;
  text-decoration: none;
}
.link:hover,.link:active,.link:focus, .richtext a:hover, .richtext a:active, .richtext a:focus {
    color: #2c2b2b;
    text-decoration: underline;
  }
.link.button, .richtext a.button {
    color: white;
    text-decoration: none;
  }
.link.button--white, .richtext a.button--white {
    color: #2c2b2b;
  }
.link.button--white:hover,.link.button--white:active,.link.button--white:focus, .richtext a.button--white:hover, .richtext a.button--white:active, .richtext a.button--white:focus {
      color: #0bffca;
    }
.link--block {
    display: block;
  }
.list, .richtext ol, .richtext ul {
  list-style: unset;
  padding-left: 2em;
}
.list .li, .richtext ol .li, .richtext ul .li {
    margin: 0 0 .5em 0;
  }
.list--blank {
    list-style-type: none;
    padding-left: 0;
  }
.list--inline {
    list-style-type: none;
    padding-left: 0;
  }
.list--inline .li {
      display: inline-block;
      margin: 0 1em 0 0;
    }
.list--ordered, .richtext ol {
    list-style-type: decimal;
  }
.list--unordered, .richtext ul {
    list-style-type: disc;
  }
.media-object {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
/* alignment variants */
.media-object--top { align-items: flex-start; }
.media-object--middle { align-items: center; }
.media-object--bottom { align-items: flex-end; }
/* sizing variants */
.media-object--2-3 > :first-child {
      flex-basis: 35%;
    }
.media-object--2-3 > :last-child {
      flex-basis: 60%;
    }
.media-object--3-2 > :first-child {
      flex-basis: 60%;
    }
.media-object--3-2 > :last-child {
      flex-basis: 35%;
    }
.media-object--50-50 > :first-child {
      flex-basis: 50%;
    }
.media-object--50-50 > :last-child {
      flex-basis: 50%;
    }
.media-object__figure {
    margin-bottom: 2rem;
    order: -10;
  }
.media-object--no-stack .media-object__figure {
      margin-bottom: 0;
      margin-top: 2rem;
      order: 0;
    }
/* figure alignment variants */
.media-object__figure--top { align-self: flex-start; }
.media-object__figure--middle { align-self: center; }
.media-object__figure--bottom { align-self: flex-end; }
/* body alignment variants */
.media-object__body--top { align-self: flex-start; }
.media-object__body--middle { align-self: center; }
.media-object__body--bottom { align-self: flex-end; }
.paragraph, .richtext p {
  display: block;
}
.paragraph--taller {
    line-height: 1.6;
  }
.richtext strong {
    font-weight: 700;
  }
.richtext em {
    font-style: italic;
  }
.richtext li {
    margin: 0 0 .5rem 0;
  }
.richtext sub, .richtext sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
.richtext sup {
    /* Move the superscripted text up */
    top: -.5em;
  }
.richtext sub {
    /* Move the subscripted text down, but only
       half as far down as the superscript moved up */
    bottom: -.25em;
  }
.richtext table {
    display: block;
    width: 100%;
  }
.richtext table tbody, .richtext table tfoot, .richtext table tr {
      display: block;
    }
.richtext table tr:nth-child(even) {
        background-color: #d2d2d2;
      }
.richtext table th, .richtext table td {
      border-bottom: 1px dotted #f2f2f2;
      display: flex;
      justify-content: flex-end;
      padding: .5rem;
    }
.richtext table th {
      display: none;
      font-weight: 700;
      text-align: left;
    }
.richtext table td::before {
        content: attr(data-heading);
        flex: 1 1 auto;
        font-weight: 700;
        text-align: left;
      }
.richtext table td > * {
        text-align: right;
      }
.select {
  appearance: none;
  border-radius: 0;
  display: inline-block;
  padding-right: 2rem;
}
.svg {
  /* Svg */
}
.table-wrapper {
  max-width: 100%;
  overflow-x: auto;
}
.table {
  width: 100%;
}
/* default styles */
.table__caption {
    caption-side: bottom;
    font-size: 1.5rem;
    font-weight: 700;
    padding: 1.5rem 0;
  }
.table__cell {
    border-bottom: 1px dotted #f2f2f2;
    padding: .5rem;
  }
.table__cell--header {
      font-weight: 700;
      text-align: left;
    }
.table--mobile-scroll .table__cell {
      /* This rule exists to better demonstrate the responsive properties */
      white-space: nowrap;
    }
.table--mobile-collapse {
    display: block;
    width: 100%;
  }
.table--mobile-collapse .table__caption, .table--mobile-collapse .table__body, .table--mobile-collapse .table__foot, .table--mobile-collapse .table__row {
      display: block;
    }
.table--mobile-collapse .table__head {
      display: none;
    }
.table--mobile-collapse .table__row:nth-child(even) {
        background-color: #d2d2d2;
      }
.table--mobile-collapse .table__cell {
      display: flex;
      justify-content: flex-end;
    }
.table--mobile-collapse .table__cell::before {
        content: attr(data-heading);
        flex: 1 1 auto;
        font-weight: 700;
        text-align: left;
      }
.textarea {
  min-height: 6rem;
  overflow: auto;
  resize: vertical;
  width: 100%;
}
.wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 73.125rem;
  padding: 0 1rem;
  position: relative;
}
.wrapper--large {
    max-width: 100rem;
  }
.wrapper--medium {
    max-width: 73.125rem;
  }
.wrapper--small {
    max-width: 53.625rem;
  }
.wrapper--featured {
    padding: 2rem 1rem;
  }
.wrapper .wrapper {
    padding-left: 0;
    padding-right: 0;
  }
.accordion {
  border-bottom: 1px solid #d2d2d2;
  padding: 1rem 0;
}
.accordion:first-of-type {
    border-top: 1px solid #d2d2d2;
  }
.accordion__drawer {
    border-radius: 2px;
    margin-bottom: 0;
  }
.accordion__drawer .expandable__drawer {
      padding: 1rem 0;
      transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
    }
.accordion__heading {
    color: #ff5454;
    cursor: pointer;
    font-weight: 700;
    padding: 1rem 4rem 1rem 0;
    position: relative;
  }
.accordion__heading:focus {
      outline: none;
    }
.accordion__heading::after {
      background-color: #ff5454;
      content: '';
      display: block;
      height: 3px;
      position: absolute;
      right: 1.5rem;
      top: 1.75rem;
      transform-origin: center;
      width: .75rem;
    }
.accordion__heading::before {
      background-color: #ff5454;
      content: '';
      display: block;
      height: 3px;
      position: absolute;
      right: 1.5rem;
      transform: rotate(90deg);
      transform-origin: center;
      transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
      top: 1.75rem;
      width: .75rem;
    }
.accordion__heading[aria-expanded="true"]::before {
        transform: rotate(180deg);
        content: '';
      }
.card-deck {
  padding: 0;
}
.card-deck__grid {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
.card-deck__grid > * {
      margin: 1rem .875rem 5rem;
    }
.card-deck__grid > *.image-card--default {
        width: 370px;
      }
.card-deck__grid > *.image-card--centered {
        margin-left: 2rem;
        margin-right: 2rem;
        width: 330px;
      }
/*! Flickity v2.2.1
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative;
}
.flickity-enabled:focus { outline: none; }
.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}
.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}
/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}
.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
/* ---- flickity-button ---- */
.flickity-button {
  position: absolute;
  background: hsla(0, 0%, 100%, 0.75);
  border: none;
  color: #333;
}
.flickity-button:hover {
  background: white;
  cursor: pointer;
}
.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F;
}
.flickity-button:active {
  opacity: 0.6;
}
.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}
.flickity-button-icon {
  fill: currentColor;
}
/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}
.flickity-prev-next-button.previous { left: 10px; }
.flickity-prev-next-button.next { right: 10px; }
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}
.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}
/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}
.flickity-rtl .flickity-page-dots { direction: rtl; }
.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}
.flickity-page-dots .dot.is-selected {
  opacity: 1;
}
.carousel {
  padding-top: 2rem;
}
.carousel__container {
    width: 100%;
  }
.carousel__cell {
    height: auto;
    margin-right: 2rem;
    padding: 1rem;
    width: 100%;
  }
.carousel__cell .image {
      width: 100%;
    }
.carousel__count {
    padding: 0 1rem 2rem;
  }
.carousel__heading {
    margin-bottom: 2rem;
  }
.carousel--dark .carousel__heading {
      color: #fff;
    }
.modal .carousel__container {
    opacity: 0;
    transition: opacity .2s cubic-bezier(.165, .84, .44, 1) .33s;
  }
.modal .carousel__container + .carousel__count {
      opacity: 0;
      transition: opacity .2s cubic-bezier(.165, .84, .44, 1) .33s;
    }
.modal .carousel__container.is-open {
      opacity: 1;
    }
.modal .carousel__container.is-open + .carousel__count {
        opacity: 1;
      }
/* Generated elements */
.flickity-viewport {
  transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
}
.flickity-button {
  background-color: #ff5454;
}
.flickity-button-icon {
    fill: #fff;
  }
.flickity-button:hover,.flickity-button:active,.flickity-button:focus {
    background-color: rgba(255, 84, 84, 0.75);
  }
.flickity-page-dots {
  bottom: 0;
  left: 0;
  max-width: 100%;
  text-align: center;
  width: 100%;
}
.carousel--no-nav .flickity-page-dots {
    display: none;
  }
.flickity-page-dots .dot {
    background-color: #2c2b2b;
    border: 2px solid #2c2b2b;
    height: 12px;
    opacity: 1;
    width: 12px;
  }
.flickity-page-dots .dot:first-child {
      margin-left: 0;
    }
.flickity-page-dots .dot.is-selected {
      background-color: transparent;
    }
.complex-form {
  padding: 2rem 1rem;
  text-align: center;
}
.complex-form__answers {
    border: 1px solid #d2d2d2;
    padding: 1rem;
  }
.complex-form__radio.input-radio {
    height: 10rem;
    flex: 1;
    flex-grow: 0;
    margin: .5rem;
    min-width: 25%;
    padding: 0;
    position: relative;
  }
.complex-form__radio.input-radio > .input[type="radio"] + .label {
      align-items: center;
      border: 1px solid #d2d2d2;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      height: 100%;
      margin: 0;
      padding: 2rem;
      text-align: center;
      width: 100%;
    }
.complex-form__radio.input-radio > .input[type="radio"] + .label::before, .complex-form__radio.input-radio > .input[type="radio"] + .label::after {
        content: none;
      }
.complex-form__radio.input-radio .input[type="radio"]:checked + .label {
      background-color: #f2f2f2;
    }
.complex-form__radio.input-radio:hover,.complex-form__radio.input-radio:active,.complex-form__radio.input-radio:focus {
      background-color: #f2f2f2;
    }
.complex-form__radio-image {
    height: 4rem;
    width: 4rem;
  }
.complex-form__radio-set {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    opacity: 0;
    transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
  }
.complex-form__radio-set > .heading {
      margin-bottom: .5rem;
      text-align: center;
      width: 100%;
    }
.complex-form__radio-set.is-visible {
      opacity: 1;
    }
.event-deck {
  padding: 2rem 0;
}
.event-deck__card {
    border-bottom: 1px solid #d2d2d2;
    padding: 2rem 0;
  }
.event-deck__card .media-object__body {
      padding: 0 2rem;
    }
.event-deck__date {
    font-family: 'Work Sans', sans-serif;
  }
.expandable {
  overflow: hidden;
  transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
}
.expandable:not(.is-expanded) .expandable__drawer {
      box-shadow: inset 0 -2px 5px 0 #d2d2d2;
    }
.global-footer {
  padding: 0 0 4rem;
  margin-top: 12rem;
}
.global-footer__legal {
    font-size: .875rem;
    line-height: 1.2rem;
  }
.global-footer__link {
    color: #ff5454;
    font-family: 'Courier';
    font-size: .875rem;
    padding: .75rem;
  }
.global-footer__link:hover,.global-footer__link:active,.global-footer__link:focus {
      color: #fff;
    }
.global-footer__navigation {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
  }
.global-footer__social {
    display: flex;
    justify-content: center;
    padding: 2rem 0 0;
  }
.global-footer__social-icon {
      padding: 0 .75rem;
    }
.global-footer__social-icon .icon {
        height: 1.75rem;
        fill: #fff;
        width: 1.75rem;
      }
.global-footer__social-icon:hover .icon,.global-footer__social-icon:active .icon,.global-footer__social-icon:focus .icon {
          fill: #ff5454;
        }
.global-footer--no-bump-box {
    padding-top: 4rem;
  }
.global-footer__bump-box {
    border-bottom: 1px solid #646464;
    padding-bottom: 6rem;
    position: relative;
    width: 100%;
  }
.global-footer__bump-box::before {
      content: '';
      position: absolute;
      bottom: calc(100% - 12rem);
      right: 0;
      width: calc(100% + .4rem);
      height: 12rem;
      background: #2c2b2b;
      transform-origin: 100% 0;
      transform: rotate(5deg) skew(5deg);
    }
.global-footer__bump-box--overlap::before {
        background-color: #fff;
        bottom: calc(100% - 16rem);
        height: 16rem;
        content: '';
      }
.global-header {
  background-color: transparent;
  height: 5.625rem;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: background-color .2s;
  z-index: 200;
}
.global-header__backdrop {
    background-color: transparent;
    bottom: 0;
    content: '';
    display: block;
    height: 100vh;
    left: 0;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
    width: 100vw;
    z-index: -1;
  }
.global-header__logo.logo {
    padding-top: 2rem;
    transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
    z-index: 201;
  }
.global-header__logo.logo::after {
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, .125);
      content: '';
      display: inline-block;
      left: -3.5rem;
      position: absolute;
      top: -2.5rem;
      transform: rotate(5deg);
      transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
      height: calc(100% + 3rem);
      width: calc(100% + 4rem);
      z-index: 199;
    }
.global-header__navigation {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
  }
.global-header__navigation > .nav-utility {
      transform: translateX(-2.875rem);
      transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
    }
.global-header__navigation > .navigation {
      margin-right: -7.625rem;
      transform: margin .2s;
    }
.global-header__navigation > .navigation > .nav-item {
        opacity: 0;
        transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
      }
.global-header__wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
@keyframes fadeOut {
  0% {
    opacity: 1;
    width: 100%;
  }

  99% {
    opacity: 0;
    padding-right: 0;
    padding-left: 0;
    width: 0;
  }

  100% {
    display: none;
  }
}
.nav-item {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  flex-grow: 0;
  outline: 0;
  padding: 0 .5rem;
  position: relative;
  text-align: center;
  transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
  width: 100%;
}
.nav-item.is-hidden {
    animation: fadeOut .2s;
    padding-right: 0;
    padding-left: 0;
    opacity: 0;
    width: 0;
  }
.nav-item[hidden] {
    display: none;
    pointer-events: none;
  }
.nav-item__icon {
    max-height: 2.8125rem;
    max-width: 2.8125rem;
    height: 100%;
    width: 100%;
  }
.nav-item__menu {
    background-color: #2c2b2b;
    bottom: 0;
    display: none;
    height: calc(100vh - 4.75rem);
    left: 100%;
    padding: 4rem 0 3.5rem;
    top: 0;
    overflow: auto;
    position: fixed;
    width: 105%;
    z-index: 200;
  }
.nav-item__menu::after {
      content: '';
      position: absolute;
      top: 100%;
      right: 0;
      width: 100%;
      height: 2rem;
      background: #2c2b2b;
      transform-origin: 100% 0;
      transform: rotate(7deg) skew(7deg);
    }
.nav-item__menu > .link {
      color: #fff;
      display: block;
      font-family: 'Courier', monospace;
      font-size: 1.25rem;
      padding: 1rem .5rem;
      text-align: left;
      white-space: nowrap;
    }
.nav-item__label {
    color: #fff;
    cursor: pointer;
    display: inline-block;
    flex-direction: column;
    font-family: 'Courier';
    font-size: 1.25rem;
    font-weight: 600;
    height: 2.8125rem;
    letter-spacing: 0;
    line-height: 1.5rem;
    text-decoration: none;
    opacity: 1;
    outline: none;
    padding: .7125rem .5rem;
    position: relative;
    transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
    white-space: nowrap;
  }
.nav-item:hover .nav-item__label,.nav-item:active .nav-item__label,.nav-item:focus .nav-item__label, .nav-item.is-open .nav-item__label {
      color: #ff5454;
    }
.nav-item:hover .nav-item__icon use,.nav-item:active .nav-item__icon use,.nav-item:focus .nav-item__icon use, .nav-item.is-open .nav-item__icon use {
      fill: #ff5454;
      stroke: #ff5454;
    }
.nav-item__link {
    display: block;
    opacity: 1;
    outline: none;
    padding: 1rem 0;
    position: relative;
    text-align: left;
  }
.nav-item__link::before {
      bottom: -.5rem;
      content: '';
      left: -.5rem;
      position: absolute;
      right: -.5rem;
      top: -.5rem;
    }
.nav-item__link:focus .nav-item__label {
        color: #ff5454;
      }
.nav-item__link > .icon {
      height: 2.8125rem;
      min-height: unset;
      min-width: unset;
      padding: .7125rem .5rem;
      transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
      width: 2.8125rem;
    }
.nav-item__link > .icon[hidden] {
        display: none;
      }
.nav-item__toggle {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline-block;
    flex-direction: column;
    opacity: 1;
    outline: none;
    padding: 0;
    position: relative;
    transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
  }
.nav-item__toggle > .icon {
      height: 2.8125rem;
      min-height: unset;
      min-width: unset;
      padding: .7125rem .5rem;
      width: 2.8125rem;
    }
.nav-item__toggle > .icon[hidden] {
        display: none;
      }
.nav-item__toggle:focus .nav-item__label {
        color: #ff5454;
      }
.nav-item__toggle:focus > .icon::before {
          border: 3px solid #00b0ff;
          box-shadow: 0 0 4px 0 #00b0ff;
          content: '';
        }
.nav-item--icon .nav-item__toggle::before {
        bottom: -.5rem;
        content: '';
        left: -.5rem;
        position: absolute;
        right: -.5rem;
        top: -.5rem;
      }
.nav-item--icon .nav-item__toggle:focus::before {
          border: 3px solid #00b0ff;
          box-shadow: 0 0 4px 0 #00b0ff;
          content: '';
        }
.nav-item.is-open > .nav-item__menu {
      display: flex;
      flex-direction: column;
    }
.navigation {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: .75rem 0;
}
.navigation::after {
    background-color: #2c2b2b;
    width: calc(100% - 2rem);
    height: calc(5.625rem + 5rem);
    content: '';
    position: absolute;
    right: -1.5rem;
    top: -4.75rem;
    transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
    z-index: 199;
  }
.navigation__back {
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    height: 2.8125rem;
    justify-content: space-between;
    opacity: 0;
    padding: .7125rem .5rem;
    position: absolute;
    right: calc(2.8125rem + 2rem);
    text-align: center;
    transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
    width: 2.8125rem;
    z-index: 200;
  }
.navigation__back[hidden] {
      pointer-events: none;
      opacity: 0;
    }
.navigation__back > .icon {
      fill: #ff5454;
      min-height: unset;
      min-width: unset;
      stroke: transparent;
    }
.navigation__back::after {
      bottom: -.5rem;
      content: '';
      left: -.5rem;
      position: absolute;
      right: -.5rem;
      top: -.5rem;
    }
.navigation__back:focus::after {
        border: 3px solid #00b0ff;
        box-shadow: 0 0 4px 0 #00b0ff;
        content: '';
      }
.navigation__drawer {
    background-color: #2c2b2b;
    border-top: 1px solid #f2f2f2;
    bottom: 0;
    left: 0;
    padding: 4rem 0;
    position: fixed;
    top: 5.625rem;
    transform: translateX(100%);
    transition: transform .2s;
    width: 100vw;
    z-index: 200;
  }
.navigation__drawer[hidden] {
      z-index: -1;
    }
.navigation__drawer .nav-item > .nav-item__toggle {
        border-bottom: 1px solid rgba(242, 242, 242, 0.33);
        padding: 1rem 0;
        width: 100%;
      }
.navigation__drawer .nav-item > .nav-item__link {
        text-align: center;
      }
.navigation__toggle {
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    display: flex;
    flex-direction: column;
    height: 2.8125rem;
    justify-content: space-between;
    opacity: 1;
    padding: .7125rem .5rem;
    position: absolute;
    right: 1rem;
    text-align: center;
    transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
    width: 2.8125rem;
    z-index: 200;
  }
.navigation__toggle > span {
      background-color: #fff;
      height: 2px;
      display: block;
      left: 0;
      margin-top: 2px;
      opacity: 1;
      transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
      width: 100%;
    }
.navigation__toggle > span:nth-child(1) {
        transform-origin: top left;
      }
.navigation__toggle > span:nth-child(3) {
        transform-origin: bottom left;
      }
.navigation__toggle::after {
      bottom: -.5rem;
      content: '';
      left: -.5rem;
      position: absolute;
      right: -.5rem;
      top: -.5rem;
    }
.navigation__toggle:focus::after {
        border: 3px solid #00b0ff;
        box-shadow: 0 0 4px 0 #00b0ff;
        content: '';
      }
.navigation.nav-open .navigation__drawer {
      transform: translateX(0);
    }
.navigation.nav-open .navigation__toggle > span:nth-child(1) {
        background-color: #ff5454;
        transform: translateY(-1px) translateX(5px) rotate(45deg);
      }
.navigation.nav-open .navigation__toggle > span:nth-child(2) {
        opacity: 0;
      }
.navigation.nav-open .navigation__toggle > span:nth-child(3) {
        background-color: #ff5454;
        transform: translateX(5px) rotate(-45deg);
      }
.navigation.nav-open + .nav-utility > .nav-item {
      opacity: 0;
    }
.navigation.sub-open .navigation__back {
      opacity: 1;
      pointer-events: initial;
    }
.navigation.sub-open .navigation__drawer {
      transform: translateX(-100%);
    }
.card.image-card {
  border: none;
  position: relative;
  padding: 0;
}
.card.image-card--centered .heading {
      color: #ff5454;
    }
.card.image-card--centered .figure {
      max-width: 330px;
    }
.card.image-card--centered .card__oval {
      border: 2px solid #ff5454;
      border-radius: 100%;
      display: block;
      height: 330px;
      position: absolute;
      top: 0;
      left: calc(50% - 165px);
      width: 330px;
    }
.card.image-card--centered .card__oval:nth-of-type(1) {
        transform: translate(12.5px, -15px);
      }
.card.image-card--centered .card__oval:nth-of-type(2) {
        transform: translate(15px, 10px);
      }
.card.image-card--centered .card__oval:nth-of-type(3) {
        transform: translate(-10px, -10px);
      }
.card.image-card--centered .card__oval:nth-of-type(4) {
        transform: translate(1rem, 0);
      }
.input-checkbox {
  padding: calc(1.5rem / 2) 1.5rem;
}
.input-radio {
  padding: calc(1.5rem / 2) 1.5rem;
}
.logo {
  background-color: transparent;
  display: inline-block;
  height: calc(100% + 4rem);
  padding: .5rem .75rem;
  position: relative;
  width: 10.25rem;
}
.logo__icon {
    display: block;
    height: auto;
    max-height: calc(5.625rem - 2rem);
    max-width: 8.75rem;
    width: auto;
  }
.logo__icon::-moz-focus-inner {
      border: 0;
    }
.logo.has-mobile {
    width: calc(2.8125rem + .5rem + .5rem);
  }
.logo.has-mobile .logo__icon {
      display: none;
    }
.logo.has-mobile .logo__icon--mobile {
        display: block;
      }
.logo__inner-wrap {
    display: inline-block;
    height: 100%;
    max-height: 5.625rem;
    position: relative;
    width: 100%;
    z-index: 200;
  }
.logo__inner-wrap::-moz-focus-inner {
      border: 0;
    }
.logo__inner-wrap::after {
      bottom: -.5rem;
      content: '';
      left: -.5rem;
      position: absolute;
      right: -.5rem;
      top: -.5rem;
    }
.logo__inner-wrap:focus {
      outline: 0;
    }
.logo__inner-wrap:focus::after {
        border: 3px solid #00b0ff;
        box-shadow: 0 0 4px 0 #00b0ff;
        content: '';
      }
.modal {
  --modal-icon-color: currentColor;
}
.modal__close {
    align-items: center;
    background-color: transparent;
    border: none;
    color: #ff5454;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font-size: 1rem;
    height: 3rem;
    justify-content: space-between;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: calc(400 + 1);
  }
.modal__close:hover,.modal__close:active,.modal__close:focus {
      color: #fff;
    }
.modal__close-icon {
      height: auto;
      margin-left: 1.5ch;
      width: 2rem;
    }
.modal__dialog {
    background-color: transparent;
    border: none;
    color: #fff;
    left: 1rem;
    margin: 0;
    padding: 3rem 0;
    position: fixed;
    top: 8rem;
    width: calc(100vw - 2rem);
    z-index: calc(400 + 1);
  }
.modal__dialog[open] {
      display: block;
    }
.modal__overlay {
    background-color: rgba(44, 43, 43, 0.9);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 400;
  }
.modal[aria-hidden='true'] {
  display: none;
}
[data-a11y-dialog-native] > .modal__overlay {
  display: none;
}
.modal__dialog[open] + &__overlay {
  display: inline-block;
}
.page-head {
  background-color: #ff5454;
  margin: 0 auto 3rem;
  max-width: 1600px;
  min-height: 15rem;
  position: relative;
}
.page-head::after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='1600' height='70' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M1600 69.698H0L1600 0z' fill='%23FFF' fill-rule='evenodd'/%3E %3C/svg%3E");
    background-position: bottom center;
    background-repeat: no-repeat;
    bottom: -1px;
    content: '';
    display: block;
    height: 70px;
    position: absolute;
    width: calc(100% + 2px);
    z-index: 0;
  }
.page-head__heading {
    bottom: 1.75rem;
    left: 0;
    line-height: 1;
    padding-top: 5.625rem;
    position: absolute;
    right: 0;
    text-align: center;
    transform: rotate(-2.5deg);
    transform-origin: bottom center;
    z-index: 1;
  }
.page-head--tall .page-head__heading {
      bottom: 1.25rem;
    }
.page-hero {
  background-position: center;
  background-size: cover;
  margin: 0 auto;
  max-width: 1600px;
  padding-bottom: 2rem;
  position: relative;
}
.page-hero::after {
    background-color: #fff;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='1600' height='70' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M1600 69.698H0L1600 0z' fill='%23FFF' fill-rule='evenodd'/%3E %3C/svg%3E");
    background-position: bottom center;
    background-repeat: no-repeat;
    bottom: -1px;
    content: '';
    display: block;
    height: 180px;
    position: absolute;
    width: calc(100% + 2px);
    z-index: 0;
  }
/* HEIGHT VARIANTS */
.page-hero--short {
    min-height: 35vh;
  }
.page-hero__layout {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    height: 100%;
  }
.page-hero__major {
    grid-column-start: 1;
    height: 100%;
    margin: 3rem auto -1.4rem;
    max-width: 400px;
    position: relative;
  }
.page-hero__minor {
    grid-column-start: 1;
    margin: 0 auto;
    max-width: 400px;
    position: relative;
  }
.page-hero__minor .image {
      position: relative;
      z-index: 3;
    }
.page-hero__emphasis {
    margin: 1.5rem 0 -6px;
    transform: rotate(-2.5deg);
    transform-origin: top left;
  }
.page-hero__emphasis .heading {
      position: relative;
    }
.page-hero__emphasis .heading span {
        margin-top: 1.5rem;
        position: relative;
        z-index: -1;
      }
.page-hero__container {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 5;
  }
.page-hero__container > .wrapper {
      height: 100%;
    }
/* EXPLICIT HEIGHT ALLOWS FOR MULTIPLE ROWS */
.page-hero__container > .wrapper.page-hero__content--spanY-first, .page-hero__container > .wrapper.page-hero__content--spanY-second, .page-hero__container > .wrapper.page-hero__content--spanY-one, .page-hero__container > .wrapper.page-hero__content--spanY-half, .page-hero__container > .wrapper.page-hero__content--spanY-1 {
          height: 50%;
        }
.page-hero__container > .wrapper.page-hero__content--spanY-two, .page-hero__container > .wrapper.page-hero__content--spanY-2, .page-hero__container > .wrapper.page-hero__content--spanY-full, .page-hero__container > .wrapper.page-hero__content--spanY-all {
          height: 100%;
        }
.page-hero__container > .wrapper.page-hero__content--spanY-2-3 {
          height: 65%;
        }
.page-hero__container > .wrapper.page-hero__content--spanY-1-3 {
          height: 33%;
        }
/* PAGE_HERO__CONTENT DEFINES CONTENT GRID */
.page-hero__content {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 1fr;
    height: 100%;
    padding: 5.625rem 0 0;
  }
.page-hero__content > * {
      height: 100%;
    }
/* DEFINES BEGINING POSITION/WIDTH OF CONTENT ALONG X-AXIS */
/* DEFINES POSITION OF CONTENT ALONG Y-AXIS */
.page-hero__content--posY-top {
        align-self: start;
      }
.page-hero__content--posY-center {
        align-self: center;
      }
.page-hero__content--posY-bottom {
        align-self: end;
      }
.page-hero__content--posY-full {
        align-self: stretch;
      }
/* DEFINES WIDTH/ENDING POINT OF CONTETN ALONG X-AXIS */
.page-hero__content--spanX-third, .page-hero__content--spanX-last, .page-hero__content--spanX-three, .page-hero__content--spanX-3, .page-hero__content--spanX-full {
        grid-column-start: 1;
      }
.page-hero + * {
    margin-top: 4rem;
  }
.split-cta {
  margin: 0 auto 2rem;
}
.split-cta__content {
    flex-grow: 1;
    margin-right: 0;
  }
/*padding: 1rem 0;*/
.split-cta__cta-list {
    text-align: left;
  }
.split-cta__cta-wrap {
    margin-top: 2rem;
    min-width: 12rem;
    text-align: center;
  }
.split-cta__inner-wrap {
    display: flex;
    flex-direction: column;
    padding: 2rem 0;
  }
.split-cta__inner-wrap .split-cta__inner-wrap {
      padding-top: 0;
    }
.split-cta--list .split-cta__cta-wrap {
      text-align: left;
    }
.split-cta--narrow-cta .split-cta__cta-wrap {
      margin-left: auto;
      margin-right: auto;
      max-width: 10rem;
    }
.staff-card__headshot {
    min-width: 22rem;
  }
.staff-list {
  padding: 2rem 0;
}
.staff-list > * + * {
    margin-top: 4rem;
  }
.staff-list__heading {
    background-color: #fff;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    padding: 0 1rem;
    position: relative;
    z-index: 1;
  }
.staff-list__heading-wrap {
    position: relative;
    text-align: center;
    width: 100%;
  }
.staff-list__heading-wrap::after {
      background-color: #d2d2d2;
      content: '';
      display: inline-block;
      height: 1px;
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      z-index: 0;
    }
.super-hero {
  /* SuperHero */
}
.tabs {
  max-width: 100%;
}
.tabs__tab-list {
    display: flex;
    justify-content: center;
    text-align: center;
  }
.tabs__tab {
    border: 0;
    background: none;
    color: #2c2b2b;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    padding: 1rem 2rem;
    position: relative;
    text-transform: uppercase;
  }
.tabs__tab:focus {
      outline: none;
    }
.tabs__tab:hover,.tabs__tab:active,.tabs__tab:focus {
      background-color: #f2f2f2;
    }
.tabs__tab::after {
      background-color: #2c2b2b;
      content: '';
      display: block;
      height: 4px;
      left: 0;
      max-width: 0;
      position: absolute;
      top: 100%;
      transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
      width: 100%;
      z-index: 100;
    }
.tabs__tab.is-active::after {
      max-width: 100%;
      content: '';
    }
.tabs__panel-list {
    max-width: 100%;
    padding: 1.5rem;
  }
.tabs__panel {
    max-width: 100%;
  }
.testimonial {
  padding: 1rem 0;
}
.testimonial .blockquote {
    padding-left: 0;
    padding-right: 0;
  }
.testimonial + .testimonial {
    border-top: 1px solid #d2d2d2;
  }
.video-embed {
  font-family: 'Courier', monospace;
  font-size: 1.25rem;
}
.video-embed .button {
    font-family: var(--font-stack-heading);
    font-size: 1.25rem;
  }
.video-embed--narrow .video-embed__video {
      border: 1px solid #2c2b2b;
      margin-left: auto;
      margin-right: auto;
      max-width: 46.875rem;
    }
.columnated-content {
  display: grid;
}
.columnated-content__primary, .columnated-content__secondary {
    padding: 1rem 0;
  }
.flex {
  flex-wrap: wrap;
  margin: 3rem 0;
}
/* until we can use Flex gap (https://caniuse.com/#search=gap) */
.flex > * {
    margin: calc(1.5rem / 2);
  }
.grid {
  display: grid;
  grid-gap: 1.5rem;
  margin: 3rem 0;
}
.major-minor__major, .major-minor__minor {
    padding: 1.5rem;
  }
.pageshell {
  margin: 0 auto;
  max-width: 100rem;
}
.sg-definition {
  border-top: 1px solid gray;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  margin: 0 2rem;
  padding: 2rem 0;
}
.sg-definition__label {
    font-weight: bold;
  }
.sg-definition--body--grid > * {
        margin-top: 2rem;
      }
.sg-definition--heading {
    display: grid;
    grid-template-columns: 1fr;
  }
.sg-definition--heading .sg-h1-is-mobile {
      font-size: 2rem !important;
    }
.sg-definition--heading .sg-h2-is-mobile {
      font-size: 1.25rem !important;
    }
.sg-definition--heading .sg-h3-is-mobile {
      font-size: 1.3125rem !important;
    }
.sg-definition--heading .sg-h4-is-mobile {
      font-size: 1.125rem !important;
    }
.sg-definition--heading .sg-h5-is-mobile {
      font-size: .875rem !important;
    }
.sg-definition--heading .sg-h6-is-mobile {
      font-size: .75rem !important;
    }
.sg-definition--palette {
    padding: 1.5rem;
  }
.sg-definition--palette .grid > * {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
.sg-definition--palette__card {
      border: 0;
      padding: 0;
    }
.sg-definition--palette__card .card {
        border: 0;
        border-bottom: 1px solid #d2d2d2;
        padding: 0 0 66.666% 0;
      }
.sg-definition--palette__card .dl {
        font-size: .7rem;
      }
.sg-definition--palette__card .figure .figcaption {
          padding: 1.5rem;
        }
.input[type="checkbox"]:focus + .label::before {
	content: '';
}
.input[type="checkbox"]:active + .label::before {
	content: '';
}
.input[type="radio"]:focus + .label::before {
	content: '';
}
.input[type="radio"]:active + .label::before {
	content: '';
}
.input[type="checkbox"]:checked + .label::after {
	content: '';
}
.input[type="radio"]:checked + .label::after {
	content: '';
}
@media only screen and (min-width: 32rem) {
.global-footer__navigation {
      flex-direction: row
  }
.page-head--tall .page-head__heading {
        bottom: 1rem
    }
.page-hero__content {
      grid-template-columns: 1fr 1fr
  }
.page-hero__content--posX-left {
          grid-column-start: 1
      }
.page-hero__content--posX-center {
          grid-column-start: 1;
          grid-column-end: span 2
      }
.page-hero__content--posX-right {
          grid-column-start: 2
      }
.page-hero__content--spanX, .page-hero__content--spanX-first, .page-hero__content--spanX-one, .page-hero__content--spanX-1 {
          grid-column-end: span 1
      }
.page-hero__content--spanX-second, .page-hero__content--spanX-two, .page-hero__content--spanX-2 {
          grid-column-end: span 2
      }
.page-hero__content--spanX-third, .page-hero__content--spanX-last, .page-hero__content--spanX-three, .page-hero__content--spanX-3, .page-hero__content--spanX-full {
          grid-column-end: span 2;
          grid-column-start: 1
      }
    }
@media only screen and (min-width: 40rem) {
.modal__dialog {
      left: calc(50% - 40vw);
      max-width: 80vw;
      padding: 5rem 0;
      top: 2rem
  }
.page-hero__emphasis {
      margin-bottom: 0;
      z-index: 1
  }
.tabs--vertical, .tabs--vert {
      align-content: flex-start;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start
  }
.tabs--vertical .tabs__tab-list, .tabs-vert .tabs__tab-list {
        flex-direction: column;
        justify-content: flex-start;
        max-width: 200px;
        width: 15%
    }

        .tabs--vertical .tabs__tab-list + .tabs__panel-list, .tabs-vert .tabs__tab-list + .tabs__panel-list {
          border-left: 1px solid #f2f2f2;
        }
.tabs--vertical .tabs__tab, .tabs-vert .tabs__tab {
        padding: 2rem 1rem
    }
.tabs--vertical .tabs__panel-list, .tabs-vert .tabs__panel-list {
        width: calc(100% - 200px)
    }

        .tabs--vertical .tabs__panel-list + .tabs__tab-list, .tabs-vert .tabs__panel-list + .tabs__tab-list {
          border-left: 1px solid #f2f2f2;
        }
    }
@media only screen and (min-width: 48rem) {
.media-object {
    flex-direction: row
}
.media-object > :first-child {
      margin-right: 2rem
  }
.media-object__figure {
      margin-bottom: 0;
      order: 0
  }
.media-object--no-stack .media-object__figure {
        margin-top: 0
    }
.richtext table {
      display: table
  }
.richtext table thead {
        display: table-header-group
    }
.richtext table tbody {
        display: table-row-group
    }
.richtext table tfoot {
        display: table-row-group
    }
.richtext table tr {
        display: table-row
    }
.richtext table th, .richtext table td {
        display: table-cell
    }
.richtext table td::before {
          content: ''
      }
.richtext table td > * {
          text-align: left
      }
.table__caption {
      caption-side: top
  }
.table--mobile-collapse {
      display: table
  }
.table--mobile-collapse .table__head {
        display: table-header-group
    }
.table--mobile-collapse .table__caption {
        display: table-caption
    }
.table--mobile-collapse .table__body {
        display: table-row-group
    }
.table--mobile-collapse .table__foot {
        display: table-row-group
    }
.table--mobile-collapse .table__row {
        display: table-row
    }
.table--mobile-collapse .table__cell {
        display: table-cell
    }
.table--mobile-collapse .table__cell::before {
          content: ''
      }
.wrapper--featured {
      padding: 2rem 0
  }
.event-deck__card .media-object__body {
        margin-left: 2rem;
        padding: 3rem
    }
.page-head {
    min-height: 16.5rem
}
.page-head__heading {
      bottom: 1.5rem
  }
.page-head--tall {
      min-height: 420px
  }
.page-head--tall .page-head__heading {
        bottom: .75rem
    }
    .page-hero::after {
      background-color: transparent;
      height: 70px;
      content: '';
    }
.page-hero--tall {
      min-height: 46rem
  }
.page-hero--short {
      min-height: 25vh
  }
.page-hero__layout {
      grid-gap: 1.5rem;
      grid-template-columns: 1fr 1fr
  }
.page-hero__major {
      margin: 3rem 0 -1.4rem
  }
.page-hero__minor {
      grid-row-start: 1;
      grid-column-start: 2;
      margin: unset;
      max-width: none
  }
.page-hero__minor .image {
        bottom: -5.5rem;
        position: absolute
    }
.page-hero__emphasis {
      bottom: 5.75rem;
      position: absolute
  }
.page-hero__container {
      position: absolute
  }
.page-hero__content--posX-center {
          grid-column-start: 2;
          grid-column-end: auto
      }
.page-hero__content--posX-right {
          grid-column-start: 3
      }
.page-hero__content--spanX-third, .page-hero__content--spanX-last, .page-hero__content--spanX-three, .page-hero__content--spanX-3, .page-hero__content--spanX-full {
          grid-column-end: span 3;
          grid-column-start: 1
      }
.split-cta__content {
      margin-right: 2rem
  }
.split-cta__cta-wrap {
      margin-top: 0
  }
.split-cta__inner-wrap {
      flex-direction: row
  }
.columnated-content {
    grid-template-columns: 1fr 1fr
}

    .columnated-content__primary, .columnated-content__secondary {
      padding: 1rem 5rem 0 0;
    }
.flex {
    display: flex;
    gap: 1.5rem
}
.flex--no-wrap {
      flex-wrap: nowrap
  }
.flex--justify-center {
      justify-content: center
  }
.flex--space-between {
      justify-content: space-between
  }
.flex--space-evenly {
      justify-content: space-evenly
  }
.grid--2col {
      grid-template-columns: 1fr 1fr
  }
.grid--3col {
      grid-template-columns: 1fr 1fr 1fr
  }
.grid--4col {
      grid-template-columns: 1fr 1fr 1fr 1fr
  }
.grid--major-minor {
      grid-template-columns: 1fr 250px
  }
.major-minor {
    display: grid;
    grid-template-columns: 2fr 1fr
}
.sg-definition {
    margin: 0 4rem;
    padding: 4.25rem 0
}
      .sg-definition--body > * {
        padding: 0 1rem;
      }
.sg-definition--body--grid > * {
          margin-top: 0
      }
.sg-definition--heading {
      grid-template-columns: 1fr 3fr 3fr
  }

      .sg-definition--heading-dynamic {
        grid-template-columns: 1fr 4fr 2fr;
      }
  }
@media only screen and (min-width: 56rem) {
.wrapper--flush {
      padding: 0
  }
.global-header__logo.logo {
      padding-top: 4rem
  }
.global-header__logo.logo::after {
        height: calc(100% + 8rem);
        left: -2.5rem;
        top: -5.5rem;
        width: calc(100% + 5rem);
        content: ''
    }
.global-header .logo__inner-wrap {
      margin-top: -2rem
  }
.global-header__navigation > .nav-utility {
        transform: translateX(0)
    }
.global-header__navigation > .navigation {
        margin-right: 0
    }
.global-header__navigation > .navigation > .nav-item {
          opacity: 1
      }
      .global-header.nav-open .global-header__backdrop {
        background-color: rgba(255, 84, 84, 0.9);
        pointer-events: initial;
        z-index: 198;
      }
.nav-item {
    height: 2.8125rem;
    margin: 0 .5rem;
    width: auto
}
.nav-item__menu {
      bottom: unset;
      height: auto;
      left: -1.875rem;
      overflow: visible;
      padding: 2.5rem 2.25rem;
      position: absolute;
      top: 3.5rem;
      width: auto
  }
.nav-item__menu > .link {
        font-size: 1rem;
        padding: .5rem 0
    }
.nav-item__label {
      color: #fff
  }
        .nav-item.is-open .nav-item__toggle > .nav-item__label::after {
          content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='10' viewBox='0 0 14 10'%3E %3Cpath fill='%23FF5454' d='M5.98493333,0.494012681 L0.313100512,7.6783255 C-0.408533408,8.58823493 0.195129335,10 1.3293751,10 L12.6730407,10 C13.792911,10 14.411568,8.6042032 13.6893153,7.6783255 L8.01748251,0.494012681 C7.48746814,-0.164670894 6.51496243,-0.164670894 5.98490387,0.494012681 L5.98493333,0.494012681 Z' transform='rotate(180 7 5)'/%3E %3C/svg%3E");
          position: absolute;
          bottom: -1.5rem;
          left: calc(50% - 5px);
          z-index: 201;
        }
.nav-item__link {
      padding: 0
  }
.navigation {
    margin-left: 2rem
}
.navigation::after {
      right: 1.5rem;
      transform: skew(-15deg, 2deg);
      top: -2.75rem;
      content: ''
  }
.navigation__back {
      opacity: 0;
      pointer-events: none;
      transition: none
  }
.navigation__drawer {
      background-color: transparent;
      border: none;
      display: flex;
      flex-direction: row;
      padding: 1.5rem 2rem 0;
      padding-right: 2rem;
      position: relative;
      top: unset;
      transform: none;
      transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
      width: auto
  }
.navigation__drawer .nav-item > .nav-item__toggle {
          border: 0;
          padding: 0
      }
.navigation__toggle {
      opacity: 0;
      pointer-events: none
  }
.navigation.sub-open .navigation__back {
        opacity: 0;
        pointer-events: none
    }
.navigation.sub-open .navigation__drawer {
        transform: translateX(0)
    }
.logo {
    height: calc(5.625rem + .5rem + .5rem);
    max-width: 15.5rem;
    width: 100%
}
.logo__icon {
      max-height: 5.625rem;
      max-width: 14.5rem
  }
.logo.has-mobile {
      width: 100%
  }

      .logo.has-mobile .logo__icon {
        display: block;
      }

        .logo.has-mobile .logo__icon--mobile {
          display: none;
        }
.logo__inner-wrap {
      height: 5.625rem
  }
.page-hero__minor .image {
        bottom: -6.5rem
    }
.page-hero__content {
      padding: 7.625rem 0 0
  }
    }
@media only screen and (min-width: 64rem) {
    .flickity-prev-next-button.next {
      right: -4.875rem;
    }

    .flickity-prev-next-button.previous {
      left: -4.875rem;
    }
.nav-item {
    margin: 0 1rem
}
.navigation__drawer {
      padding-right: 5rem
  }
.page-head {
    min-height: 22.5rem
}
.page-head__heading {
      bottom: 1.25rem
  }
.page-head--tall .page-head__heading {
        bottom: 0
    }
.page-hero__minor .image {
        bottom: -7.5rem
    }
.page-hero__emphasis {
      bottom: 6rem
  }
.split-cta__content {
      margin-right: 8rem
  }
.staff-list {
    padding: 4rem 0
}
.staff-list > * + * {
      margin-top: 8rem
  }
  }
@media only screen and (min-width: 72rem) {
        .card-deck__grid > *:nth-child(3n + 3) {
          margin-right: 0;
        }

        .card-deck__grid > *:first-child, .card-deck__grid > *:nth-child(3n + 1):not(:nth-last-child(2)) {
          margin-left: 0;
        }
.page-hero__minor .image {
        bottom: -9rem
    }
.page-hero__emphasis {
      bottom: 6.25rem
  }
      }
@media screen and (min-width: 1280px) {
	.heading--h1, .richtext h1 {
		font-size: 2.25rem;
	}
	.heading--h2, .richtext h2 {
		font-size: 1.5rem;
	}
	.heading--h3, .richtext h3 {
		font-size: 1.3125rem;
	}
	.heading--h4, .richtext h4 {
		font-size: 1.125rem;
	}
	.heading--h5, .richtext h5 {
		font-size: .875rem;
	}
	.heading--h6, .richtext h6 {
		font-size: .75rem;
	}
	.heading--hero {
		font-size: 5rem;
	}
	.page-head--tall .heading--hero {
		font-size: 12.5rem;
	}
	.heading--enormous {
		font-size: 12.5rem;
	}
}
@media only screen and (max-width: 48rem) {
    .sg-definition > * + * {
      margin-top: 2rem;
    }
  }
@media screen and (max-width: 420px) {
	.heading--h1, .richtext h1 {
		font-size: 2rem;
	}
	.heading--h2, .richtext h2 {
		font-size: 1.25rem;
	}
	.heading--h3, .richtext h3 {
		font-size: 1.3125rem;
	}
	.heading--h4, .richtext h4 {
		font-size: 1.125rem;
	}
	.heading--h5, .richtext h5 {
		font-size: .875rem;
	}
	.heading--h6, .richtext h6 {
		font-size: .75rem;
	}
	.heading--hero {
		font-size: 2rem;
	}
	.page-head--tall .heading--hero {
		font-size: 5rem;
	}
	.heading--enormous {
		font-size: 5rem;
	}
}
